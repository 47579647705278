<template>
	<div>
		<template v-if="categoryList.length > 0">
			<div class="room-setting">
				<a-row type="flex" justify="start">
					<a-col :span="4">
						<div class="cate-list">
							<div class="flex justify-between table-operation">
								<!-- <a-space> -->
								<a-button
									type="primary"
									size="large"
									style="width: 100%; margin-right: 10px"
									@click="
										categoryTitle = '添加分类'
										categoryVisible = true
										categoryForm = {
											title: '',
											type_id: defaultRoomType.id
										}
									">
									添加分类
								</a-button>
								<a-button
									type="primary"
									size="large"
									style="width: 100%"
									@click="
										categoryTitle = '桌型设置'
										roomTypeModalShow = true
									">
									桌型设置
								</a-button>
								<!-- </a-space> -->
							</div>
							<a-table id="table" :data-source="categoryList" :columns="columns" :customRow="tableCustomRow" :rowClassName="record => (record.id === selectedRow ? 'selected-row' : '')" rowKey="id" :showHeader="false" :pagination="false">
								<div class="flex justify-end" slot="action" slot-scope="record">
									<a class="select-none mr-5" @click.stop="categoryEdit(record)"> 编辑 </a>
									<a class="select-none" @click.stop="categoryDel(record)">删除</a>
								</div>
							</a-table>
						</div>
					</a-col>
					<a-col :span="20">
						<div class="room-list">
							<template v-if="roomList.length > 0">
								<div class="table-operation">
									<a-button type="primary" size="large" @click="roomAdd()"> 添加餐位 </a-button>
								</div>
								<a-row :gutter="[25, 25]" id="room-list">
									<a-col :xxl="{span: 6}" :xl="{span: 8}" :lg="{span: 12}" :md="{span: 12}" v-for="item in roomList" :key="item.id" class="room-move-item">
										<div class="room">
											<div class="room-main">
												<div class="room-name">
													{{ item.title }}
												</div>
												<div class="room-number">容客人数：{{ item.number }}人</div>
											</div>
											<div class="room-footer">
												<button class="flex justify-center items-center flex-1" @click="roomEdit(item)">
													<a-icon type="edit" class="mr-1" />
													编辑
												</button>
												<a-divider type="vertical" />
												<button class="flex justify-center items-center flex-1" @click="roomDel(item)">
													<a-icon type="delete" class="mr-1" />
													删除
												</button>
											</div>
										</div>
									</a-col>
								</a-row>
							</template>
							<template v-else>
								<div class="room-empty">
									<img class="cate-empty-img" src="@/assets/dashboard-empty.svg" alt="" />
									<a-button size="large" type="primary" icon="plus" @click="roomAdd()"> 添加餐位 </a-button>
								</div>
							</template>
						</div>
					</a-col>
				</a-row>
			</div>
		</template>
		<template v-else>
			<div class="cate-empty">
				<img class="cate-empty-img" src="@/assets/dashboard-empty.svg" alt="" />
				<a-button
					size="large"
					type="primary"
					icon="plus"
					@click="
						categoryTitle = '添加分类'
						categoryVisible = true
					">
					添加分类
				</a-button>
			</div>
		</template>
		<!--分类名称弹窗-->
		<a-modal
			width="800px"
			:visible.sync="categoryVisible"
			:title="categoryTitle"
			@cancel="
				$refs.category.clearValidate()
				categoryVisible = false
				categoryForm = {}
			"
			@ok="categoryConfirm">
			<a-form-model ref="category" :model="categoryForm" :label-col="{span: 5}" :wrapper-col="{span: 18}">
				<a-form-model-item
					prop="title"
					label="分类名称"
					:rules="{
						required: true,
						message: '请输入分类名称(不超过5个字符)',
						max: 5
					}">
					<a-input v-model="categoryForm.title" placeholder="请输入分类名称" />
				</a-form-model-item>
				<a-form-model-item prop="title" label="桌型选择">
					<a-select v-model="categoryForm.type_id" defaultActiveFirstOption :options="roomTypeOptionsData" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!--添加/修改桌型弹窗-->
		<a-modal
			width="800px"
			:visible.sync="roomTypeAddModalShow"
			:title="categoryTitle"
			@cancel="
				roomTypeAddModalShow = false
				roomTypeAvailableValue = []
				roomTypeFacilitiesValue = []
				roomTypeForm = {
					name: '',
					id: '',
					images: [],
					facilities: [],
					description: '',
					ding_show: 1,
					ding_times: {
						near_hour: 1,
						early_day: 0,
						times: []
					},
					min: 1,
					max: 12
				}
				roomTypeBeforeDay = false
				roomTypeDisabledHour = true
			"
			@ok="roomTypeAddConfirm">
			<a-spin :spinning="isSpinning">
				<a-form-model ref="roomTypeForm" :model="roomTypeForm" :label-col="{span: 5}" :wrapper-col="{span: 18}">
					<a-form-model-item prop="id" style="display: none">
						<a-input v-model="roomTypeForm.id" type="hidden" />
					</a-form-model-item>
					<a-form-model-item
						prop="name"
						label="桌型名称"
						:rules="{
							required: true,
							message: '请输入桌型名称(不超过5个字符)',
							max: 5
						}">
						<a-input v-model="roomTypeForm.name" placeholder="请输入桌型名称" />
					</a-form-model-item>
					<a-form-model-item
						prop="min"
						label="容客人数"
						:rules="{
							required: true,
							validator: validateMinMax,
							trigger: 'change'
						}">
						<div class="flex justify-start staple-date staple-number">
							<div class="flex justify-center">
								<p class="left">至少</p>
								<a-input addon-after="人" v-model="roomTypeForm.min" />
								<p class="right">起订</p>
							</div>
							<div class="flex justify-center">
								<p class="left">最多</p>
								<a-input addon-after="人" v-model="roomTypeForm.max" />
								<p class="right">可订</p>
							</div>
						</div>
					</a-form-model-item>
					<a-form-model-item prop="picture" label="桌型展示">
						<img-uploader v-model="roomTypeForm.images" model-type="Arr" :max="5" :showUploadList="true" :multiple="true" />
						<div class="flex text-sm">
							<div class="text-theme-red mr-2">注:</div>
							<div>
								<p>格式必须为JPG/PNG（建议图片比例为横屏16：9）</p>
							</div>
						</div>
					</a-form-model-item>
					<a-form-model-item prop="facilities" label="桌型设施">
						<div class="flex justify-start facility-list">
							<div class="facility-item" :class="roomTypeFacilitiesValue.includes(item) ? 'facility-item_checked' : ''" v-for="item in roomTypeFacilities" :key="item" @click="onSelectFacility(item)">{{ item }}</div>
						</div>
					</a-form-model-item>
					<a-form-model-item prop="description" label="桌型描述">
						<a-textarea v-model="roomTypeForm.description" placeholder="请输入描述内容" />
					</a-form-model-item>
					<a-form-model-item prop="ding_times" label="订座时间">
						<div class="flex justify-start staple-date">
							<div class="flex justify-center">
								<a-checkbox class="staple-checkbox" :checked="roomTypeBeforeDay" @change="onChangeDingTimes(0, roomTypeBeforeDay)" />
								<p class="left">至少提前</p>
								<a-input addon-after="天" type="number" min="0" max="30" v-model="displayEarlyDay" :disabled="!roomTypeBeforeDay" />
								<p class="right">预订</p>
							</div>
							<div class="flex justify-center">
								<a-checkbox class="staple-checkbox" :checked="roomTypeDisabledHour" @change="onChangeDingTimes(1, roomTypeDisabledHour)" />
								<p class="left">近</p>
								<a-input addon-after="小时" type="number" min="0" max="23" v-model="displayDisabledHour" :disabled="!roomTypeDisabledHour" />
								<p class="right">内不可预订</p>
							</div>
						</div>
						<div class="flex justify-start">
							<a-checkbox-group v-model="roomTypeAvailableValue" name="checkboxgroup" :options="roomTypeAvailable" />
						</div>
					</a-form-model-item>
					<a-form-model-item prop="description" label="订座对外展示">
						<a-switch :checked="roomTypeForm.ding_show === 1 ? true : false" @change="onChangeSwitch" />
					</a-form-model-item>
				</a-form-model>
			</a-spin>
		</a-modal>
		<!--桌型列表弹窗-->
		<a-modal width="800px" :visible.sync="roomTypeModalShow" :footer="null" title="桌型设置" @cancel="roomTypeModalShow = false">
			<a-button
				type="primary"
				@click="
					categoryTitle = '添加桌型'
					roomTypeAddModalShow = true
				"
				style="margin-bottom: 20px">
				添加桌型
			</a-button>
			<a-table :data-source="roomTypeData" :pagination="false" rowKey="id">
				<a-table-column key="name" title="名称" data-index="name" />
				<a-table-column key="images" title="图片">
					<template slot-scope="text, record">
						<img :src="record.images && record.images.length > 0 ? record.images[0] : 'https://oss.dinggebaojian.com/mini/tg_poster.png'" alt="图片" class="room-type-cover" />
					</template>
				</a-table-column>
				<a-table-column key="desc" title="描述" data-index="description" :width="350" />
				<a-table-column key="action" title="操作">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" icon="edit" type="primary" @click="roomTypeModalConfirm(record)">编辑 </a-button>
							<a-button size="small" icon="delete" @click="roomTypeDelete(record)" v-if="record.default == 0"> 删除</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-modal>
		<!--餐位弹窗-->
		<a-modal
			width="800px"
			:visible.sync="roomVisible"
			:title="roomTitle"
			@cancel="
				$refs.roomForm.clearValidate()
				roomVisible = false
				roomForm = {
					vr_url: {
						url: '',
						picture: ''
					},
					video_url: {
						url: [],
						picture: ''
					},
					show: 0
				}
				roomFacilities = []
				roomFacilitiesValue = []
				menuCategoryValue = []
			"
			@ok="roomConfirm">
			<a-form-model ref="roomForm" :model="roomForm" :label-col="{span: 5}" :wrapper-col="{span: 17}" :rules="roomRules">
				<!-- :rules="{
					required: true,
					message: '请输入餐位名称',
					max: 9,
				}" -->
				<a-form-model-item prop="title" label="餐位名称">
					<a-input v-model="roomForm.title" placeholder="请输入餐位名称" />
				</a-form-model-item>
				<a-form-model-item
					prop="number"
					label="容客人数"
					:rules="{
						required: true,
						message: '请输入容客人数',
						type: 'number',
						min: 1
					}">
					<a-input v-model.number="roomForm.number" class="w-full" type="number" placeholder="请输入容客人数" />
				</a-form-model-item>
				<a-form-model-item
					v-if="roomTitle === '编辑餐位'"
					prop="number"
					label="移动分类"
					:rules="{
						required: true,
						message: '请选择移动分类'
					}">
					<a-select v-model="roomForm.category_id" placeholder="请选择移动分类">
						<a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">
							{{ item.title }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item prop="facilities" label="餐位设施">
					<div class="flex justify-start facility-list">
						<div class="facility-item" :class="roomFacilitiesValue.includes(item) ? 'facility-item_checked' : ''" v-for="item in roomFacilities" :key="item" @click="onSelectRoomFacility(item)">{{ item }}</div>
					</div>
				</a-form-model-item>
				<a-form-model-item prop="show" label="展示类型">
					<a-radio-group v-model="roomForm.show" @change="validatorType">
						<a-radio :value="0">不对外展示</a-radio>
						<a-radio :value="1">图片展示</a-radio>
						<a-radio :value="2">VR展示</a-radio>
						<a-radio :value="3">视频展示</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<template v-if="roomForm.show === 1">
					<a-form-model-item
						prop="picture"
						label="餐位展示"
						:rules="{
							required: roomForm.show === 1,
							message: '请选择餐位展示图片'
						}">
						<img-uploader v-model="roomForm.picture" :max="9" model-type="Arr" :showUploadList="true" :multiple="true" />
						<!-- <template #help> -->
						<div class="flex text-sm">
							<div class="text-theme-red mr-2">注:</div>
							<div>
								<p>格式必须为JPG/PNG（建议尺寸：宽460px，高300px）</p>
							</div>
						</div>
						<!-- </template> -->
					</a-form-model-item>
				</template>
				<template v-if="roomForm.show === 2">
					<a-form-model-item
						label="VR链接"
						prop="vr_url.url"
						:rules="{
							required: roomForm.show === 2,
							message: '请输入VR链接'
						}">
						<a-input v-model="roomForm.vr_url.url" class="w-full" placeholder="请输入VR链接" />
					</a-form-model-item>
					<a-form-model-item
						label="VR图片"
						ref="vr_url"
						prop="vr_url.picture"
						:rules="{
							required: roomForm.show === 2,
							message: '请选择VP图片'
						}">
						<img-uploader v-model="roomForm.vr_url.picture" @success="$refs.vr_url.onFieldBlur()" @remove="$refs.vr_url.onFieldBlur()" />
					</a-form-model-item>
				</template>
				<template v-if="roomForm.show === 3">
					<a-form-model-item
						label="视频"
						ref="video_url"
						prop="video_url.url"
						:rules="{
							required: roomForm.show === 3,
							message: '请选择上传视频'
						}">
						<a-upload :fileList="roomForm.video_url.url" :beforeUpload="beforeUpload" :customRequest="customRequest" :remove="videoUrlRemove">
							<a-button>
								<a-icon :type="videoUploadLoading ? 'loading' : 'upload'" />
								上传视频
							</a-button>
						</a-upload>
					</a-form-model-item>
					<a-form-model-item
						label="视频展示图片"
						ref="video_url"
						prop="video_url.picture"
						:rules="{
							required: roomForm.show === 3,
							message: '请选择上传视频展示图片'
						}">
						<img-uploader v-model="roomForm.video_url.picture" @success="$refs.video_url.onFieldBlur()" @remove="$refs.video_url.onFieldBlur()" />
					</a-form-model-item>
				</template>
				<a-form-model-item prop="facilities" label="隐藏菜单分类">
					<div class="flex justify-start facility-list">
						<div class="facility-item facility-item_hidden" :class="menuCategoryValue.includes(item.id) ? 'facility-item_hidden_checked' : ''" v-for="item in menuCategoryList" :key="item.id" @click="onSelectMenuCategory(item.id)">{{ item.name }}（ID:{{ item.id }}）</div>
					</div>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import Sortable from 'sortablejs'
import {message} from 'ant-design-vue'
import {ossconfig} from '@/api'
import {category} from '@/api/menuManage'
import {delRoom, delRoomCategory, getRoom, getRoomCategoryList, roomType, saveRoom, saveRoomCategory, sortRoom, sortRoomCategory} from '@/api/roomSetting'
import ImgUploader from '@/components/imgUploader'
import request from '@/utils/request'

export default {
	name: 'RoomSetting',
	components: {ImgUploader},
	data() {
		return {
			columns: [
				{
					dataIndex: 'title',
					ellipsis: true
				},
				{
					key: 'action',
					scopedSlots: {customRender: 'action'}
				}
			],
			isSpinning: false,
			roomId: undefined,
			selectedRow: '',
			categoryList: [],
			roomList: [],
			categoryForm: {
				title: '',
				type_id: 0
			},
			categoryVisible: false,
			roomTypeModalShow: false,
			roomTypeAddModalShow: false,
			categoryTitle: '添加分类',
			roomVisible: false,
			roomTitle: '添加餐位',
			roomForm: {
				title: '',
				number: '',
				picture: [],
				vr_url: {
					url: '',
					picture: ''
				},
				video_url: {
					url: [],
					picture: ''
				},
				show: 0,
				sort: 0
			},
			roomFacilities: [],
			roomFacilitiesValue: [],
			roomRules: {
				title: [
					{
						required: true,
						message: '请输入餐位名称',
						trigger: 'blur'
					},
					{
						min: 1,
						max: 9,
						message: '字数不可超过9个字符',
						trigger: 'blur'
					}
				]
			},
			tableCustomRow: record => ({
				on: {
					click: () => {
						this.selectedRow = record.id
					}
				}
			}),
			//桌型相关变量
			roomTypeForm: {
				id: '',
				name: '',
				images: [],
				facilities: [], // 设施
				description: '', // 描述
				ding_show: 1,
				ding_times: {
					near_hour: 0,
					early_day: 0,
					times: []
				},
				min: 1,
				max: 12
			},
			roomTypeBeforeDay: false,
			roomTypeDisabledHour: true,
			roomTypeAvailable: [],
			roomTypeAvailableValue: [],
			roomTypeData: [],
			roomTypeFacilities: [],
			roomTypeFacilitiesValue: [],
			roomTypeOptionsData: [],
			initFacilities: {},
			//默认桌型
			defaultRoomType: {},
			videoUploadLoading: false, //上传视频展示
			menuCategoryList: [], // 可选菜单分类列表
			menuCategoryValue: [] // 选中的菜单分类ID值
		}
	},
	async mounted() {
		await this.getRoomCategoryList()
		if (this.categoryList && this.categoryList.length) {
			this.selectedRow = this.categoryList[0].id
		}
		//  拖拽事件一定要放在 mounted 生命周期内，因为这时真实的DOM才渲染出来
		this.initCateSortable()
		//加载桌型列表
		this.getRoomTypeList()
		this.fetchMenuCategory()
	},
	methods: {
		/**
		 * @description: 容客人数校验
		 * @return {*}
		 * @param {*} rule
		 * @param {*} value
		 * @param {*} callback
		 */
		validateMinMax(rule, value, callback) {
			const {min, max} = this.roomTypeForm
			if (!min) {
				callback(new Error('请输入至少起订人数'))
			} else if (!max) {
				callback(new Error('请输入最多可订人数'))
			} else if (Number(min) > Number(max)) {
				callback(new Error('至少起订人数不能大于最多可订人数'))
			} else {
				callback()
			}
		},
		//删除视频
		videoUrlRemove() {
			this.roomForm.video_url.url = []
		},
		// 视频文件上传前钩子函数
		beforeUpload(file) {
			return new Promise((resolve, reject) => {
				const isJpgOrPng = ['mp4', 'flv'].includes(file.name.split('.')[1])
				if (isJpgOrPng) resolve()
				else {
					this.$message.warning('请上传mp4/flv格式的视频！')
					reject()
				}
			})
		},
		random_string(e) {
			e = e || 32
			const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
			const a = t.length
			let n = ''
			for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
			return n
		},
		formatFileName(name) {
			const index = name.lastIndexOf('.')
			if (index !== -1) {
				return name.substring(index)
			} else {
				return ''
			}
		},
		// 覆盖默认上传函数
		async customRequest(file) {
			this.videoUploadLoading = true
			const {
				data: {url: ossUrl, multipart_params: multipartParams}
			} = await ossconfig()
			this.$refs.roomForm.clearValidate()
			const formData = new FormData()
			const name = this.random_string(15) + this.formatFileName(file.file.name)
			Object.keys(multipartParams).forEach(index => {
				if (index === 'key') {
					multipartParams.key += name
				}
				formData.append(index, multipartParams[index])
			})
			formData.append('file', file.file)
			formData.append('name', name)
			await request({url: ossUrl, method: 'post', data: formData}).then(res => {
				this.roomForm.video_url.url = [
					{
						name: res.data.filename,
						url: ossUrl + res.data.filename,
						status: 'done',
						uid: 1
					}
				]
			})
			// this.$refs.roomForm.clearValidate()
			this.videoUploadLoading = false
		},
		/**
		 * @description: 获取可选菜单分类列表
		 * @return {*}
		 */
		async fetchMenuCategory() {
			let {code, data} = await category.list()

			if (code === 0) {
				this.menuCategoryList = data
			}
		},
		/**
		 * @description: 获取分类列表
		 * @return {*}
		 */
		async getRoomCategoryList() {
			await this.$store.dispatch('loading/startContentLoading')
			await getRoomCategoryList()
				.then(res => {
					if (res.code === 0) {
						this.categoryList = res.data
					}
					this.$store.dispatch('loading/endContentLoading')
				})
				.catch(() => this.$store.dispatch('loading/endContentLoading'))
		},
		/**
		 * @description: 分类编辑
		 * @return {*}
		 */
		categoryEdit(record) {
			this.categoryForm = _.cloneDeep(record)
			this.categoryTitle = '编辑分类'
			this.categoryVisible = true
		},
		/**
		 * @description: 分类删除
		 * @return {*}
		 */
		categoryDel({id}) {
			const h = this.$createElement
			this.$confirm({
				width: '600px',
				title: '删除分类',
				content: '确定要删除该分类和分类下的内容吗？',
				icon: () =>
					h('a-icon', {
						props: {type: 'info-circle'},
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({content: '加载中', key: 'delCate'})
					delRoomCategory({id}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delCate'
							})
							this.getRoomCategoryList()
						}
					})
				}
			})
		},
		/**
		 * @description: 分类保存
		 * @return {*}
		 */
		categoryConfirm() {
			this.$refs.category
				.validate()
				.then(() => {
					this.$message.loading({
						content: '保存中',
						key: 'category'
					})
					saveRoomCategory(this.categoryForm).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '保存成功',
								key: 'category'
							})
							this.selectedRow = res.data.id
							this.categoryVisible = false
							this.categoryForm = {}
							this.getRoomCategoryList()
						}
					})
				})
				.catch(err => err)
		},
		//添加桌型
		roomTypeAddConfirm() {
			this.$refs.roomTypeForm.validate().then(() => {
				this.isSpinning = true

				// 格式化默认桌型展示图片字段
				if (this.roomTypeForm.images === null) this.roomTypeForm.images = new Array()

				// 格式化默认桌型设施字段
				if (this.roomTypeFacilitiesValue.length) {
					let tmpArray = []
					let compareNumbers = (a, b) => {
						return a - b
					}
					let findObjectKey = (obj, value) => {
						return Object.keys(obj).find(key => obj[key] === value)
					}
					// 根据所选值找出init数组中的key值
					this.roomTypeFacilitiesValue.forEach(item => {
						tmpArray.push(findObjectKey(this.initFacilities, item))
					})

					// 排序
					this.roomTypeForm.facilities = tmpArray.sort(compareNumbers)

					// 去重
					this.roomTypeForm.facilities = Array.from(new Set(this.roomTypeForm.facilities))
				} else {
					this.roomTypeForm.facilities = []
				}

				// 格式化默认桌型订座时间范围字段
				if (this.roomTypeAvailableValue.length) {
					let tmpArray = []

					this.roomTypeAvailableValue.forEach(item => {
						this.roomTypeAvailable.forEach((item_child, index_child) => {
							if (item_child === item) tmpArray.push(index_child + 1)
						})
					})

					// 去重
					this.roomTypeForm.ding_times.times = Array.from(new Set(tmpArray))
				} else {
					this.roomTypeForm.ding_times.times = []
				}

				// 格式化默认桌型订座时间范围字段
				if (this.roomTypeForm.ding_times.early_day > 30) {
					message.error('至少提前天数不能超过30天')

					this.roomTypeForm.ding_times.early_day = 30
					this.isSpinning = false

					return
				}

				if (this.roomTypeForm.ding_times.near_hour > 23) {
					message.error('最大不可预订时间不能超过23小时')

					this.roomTypeForm.ding_times.near_hour = 23
					this.isSpinning = false

					return
				}

				this.roomTypeForm.ding_times.early_day = Number(this.roomTypeForm.ding_times.early_day)
				this.roomTypeForm.ding_times.near_hour = Number(this.roomTypeForm.ding_times.near_hour)

				// 根据桌型订座时间（单选）重置字段
				if (this.roomTypeBeforeDay) this.roomTypeForm.ding_times.near_hour = 0
				if (this.roomTypeDisabledHour) this.roomTypeForm.ding_times.early_day = 0

				roomType
					.add(this.roomTypeForm)
					.then(res => {
						this.isSpinning = false
						if (res.code === 0) {
							message.success('添加成功')
							this.getRoomTypeList()
							this.roomTypeAddModalShow = false
							this.roomTypeForm = {
								name: '',
								id: '',
								picture: [],
								facilities: [], // 设施
								description: '', // 描述
								ding_show: 1,
								ding_times: {
									near_hour: 1,
									early_day: 0,
									times: []
								},
								min: 1,
								max: 12
							}
							this.roomTypeAvailableValue = []
							this.roomTypeFacilitiesValue = []
							this.roomTypeBeforeDay = false
							this.roomTypeDisabledHour = true
						}
					})
					.catch(err => {
						this.isSpinning = false
					})
			})
		},
		//桌型列表
		getRoomTypeList() {
			roomType.getList().then(res => {
				this.roomTypeAvailable = Object.values(res.available_date)
				this.roomTypeData = res.data
				this.roomTypeFacilities = Object.values(res.facilities)
				this.roomTypeOptionsData = []
				this.initFacilities = res.facilities // 初始化格式前设施内容
				res.data.map(item => {
					this.roomTypeOptionsData.push({
						value: item.id,
						label: item.name
					})
					if (item.default === 1) {
						this.defaultRoomType = item
					}
				})
			})
		},
		//桌型编辑
		roomTypeModalConfirm(record) {
			this.roomTypeForm = _.cloneDeep(record)

			// 格式化桌型设施字段反显
			if (this.roomTypeForm.facilities && this.roomTypeForm.facilities.length > 0) {
				this.roomTypeForm.facilities.forEach(item => {
					this.roomTypeFacilitiesValue.push(this.initFacilities[item])
				})
			}

			// 格式化桌型订座日期字段反显
			if (this.roomTypeForm.ding_times.times.length) {
				this.roomTypeForm.ding_times.times.forEach(item => {
					this.roomTypeAvailable.forEach((item_child, index_child) => {
						if (item === index_child + 1) this.roomTypeAvailableValue.push(item_child)
					})
				})
			}

			// 格式化桌型订座时间单选反显
			if (this.roomTypeForm.ding_times.early_day > 0 && this.roomTypeForm.ding_times.near_hour > 0) {
				this.roomTypeBeforeDay = false
				this.roomTypeDisabledHour = true
			} else {
				if (this.roomTypeForm.ding_times.near_hour > 0) {
					this.roomTypeBeforeDay = false
					this.roomTypeDisabledHour = true
				} else {
					this.roomTypeBeforeDay = true
					this.roomTypeDisabledHour = false
				}
			}

			this.categoryTitle = '编辑桌型'
			this.roomTypeAddModalShow = true
		},
		//删除桌型
		roomTypeDelete({id}) {
			const h = this.$createElement
			this.$confirm({
				width: '600px',
				title: '删除桌型',
				content: '确定要删除桌型吗？',
				icon: () =>
					h('a-icon', {
						props: {type: 'info-circle'},
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({content: '加载中', key: 'delCate'})
					roomType.delete({id}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delCate'
							})
							this.getRoomTypeList()
						}
					})
				}
			})
		},
		/**
		 * @description: 编辑餐位弹窗
		 * @return {*}
		 * @param {*} record
		 */
		roomEdit(record) {
			this.roomId = record.id
			this.roomTitle = '编辑餐位'
			this.roomForm = _.cloneDeep(record)
			// 获取当前分类所属桌型
			this.categoryList.forEach(item => {
				if (item.id === record.category_id) {
					let tmpRoomTypeData = this.roomTypeData.find(item_child => item_child.id === item.type_id)
					// 设置餐位所属分类的桌型可选设施列表
					this.roomFacilities = _.cloneDeep(tmpRoomTypeData.facilities_list)
					// 默认选中桌型已有的全部设施
					this.roomFacilitiesValue = _.cloneDeep(record.facilities_list)
				}
			})
			// 获取预订菜可选分类
			this.menuCategoryValue = _.cloneDeep(record.menu_category || [])

			this.roomVisible = true
		},
		/**
		 * @description: 添加餐位弹窗
		 * @return {*}
		 */
		roomAdd() {
			this.roomId = undefined
			this.roomTitle = '添加餐位'
			// 获取当前分类所属桌型
			this.categoryList.forEach(item => {
				if (item.id === this.selectedRow) {
					let tmpRoomTypeData = this.roomTypeData.find(item_child => item_child.id === item.type_id)
					// 设置餐位所属分类的桌型可选设施列表
					this.roomFacilities = _.cloneDeep(tmpRoomTypeData.facilities_list)
					// 默认选中桌型已有的全部设施
					this.roomFacilitiesValue = _.cloneDeep(tmpRoomTypeData.facilities_list)
				}
			})
			this.roomVisible = true
		},
		roomDel({id}) {
			this.$confirm({
				width: '600px',
				title: '删除餐位',
				content: '删除后，该餐位的订单将无法在电子预订本显示和查询！',
				icon: () =>
					this.$createElement('a-icon', {
						props: {type: 'info-circle'},
						style: 'color:#e63e30'
					}),
				onOk: () => {
					this.$message.loading({content: '加载中', key: 'delRoom'})
					delRoom({id}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '删除成功',
								key: 'delRoom'
							})
							this.getRoomList()
						}
					})
				}
			})
		},
		/**
		 * 提交-餐位添加
		 */
		roomConfirm() {
			const type = this.roomForm.show / 1
			const video_url = this.roomForm.video_url.url
			this.$refs.roomForm
				.validate()
				.then(() => {
					let messageError = ''
					if (type === 2) {
						if (!this.roomForm.vr_url.url) messageError = '请输入VR链接'
						if (!this.roomForm.vr_url.picture) messageError = '请上传VR图片'
					}
					if (type === 3 && (!video_url || !video_url.length)) messageError = '请上传视频'
					if (type === 3 && !this.roomForm.video_url.picture) messageError = '请上传视频展示图片'
					if (messageError) {
						this.$message.error(messageError)
						return
					}
					this.$message.loading({
						content: '保存中',
						key: 'category'
					})

					let params = {
						id: this.roomId,
						category_id: this.roomForm.category_id || this.selectedRow,
						title: this.roomForm.title,
						number: this.roomForm.number,
						show: this.roomForm.show,
						sort: this.roomForm.sort,
						picture: type === 1 ? this.roomForm.picture : [],
						vr_url: {
							url: type === 2 ? this.roomForm.vr_url.url : '',
							picture: type === 2 ? this.roomForm.vr_url.picture : ''
						},
						video_url: {
							url: type === 3 ? video_url[0].url : '',
							picture: type === 3 ? this.roomForm.video_url.picture : ''
						}
					}

					// 格式化餐位设施字段
					if (this.roomFacilitiesValue.length) {
						let tmpArray = []
						let compareNumbers = (a, b) => {
							return a - b
						}
						let findObjectKey = (obj, value) => {
							return Object.keys(obj).find(key => obj[key] === value)
						}
						// 根据所选值找出init数组中的key值
						this.roomFacilitiesValue.forEach(item => {
							tmpArray.push(findObjectKey(this.initFacilities, item))
						})

						// 排序
						let tmpFacilities = tmpArray.sort(compareNumbers)

						// 去重
						params.facilities = Array.from(new Set(tmpFacilities))
					} else {
						params.facilities = []
					}

					// 格式话预点菜可选分类
					if (this.menuCategoryValue.length) {
						params.menu_category = this.menuCategoryValue
					}

					saveRoom(params).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '保存成功',
								key: 'category'
							})
							this.roomVisible = false
							this.roomForm = {
								vr_url: {
									url: '',
									picture: ''
								},
								video_url: {
									url: [],
									picture: ''
								},
								show: 0
							}
							this.roomFacilities = []
							this.roomFacilitiesValue = []
							this.menuCategoryValue = []
							this.getRoomList()
						}
					})
				})
				.catch(err => {
					console.log(err)
				})
		},
		getRoomList() {
			this.$store.dispatch('loading/startContentLoading')
			getRoom({category_id: this.selectedRow})
				.then(res => {
					if (res.code === 0) {
						const list = res.data || []
						list.length &&
							list.forEach(item => {
								if (!item.vr_url) {
									item.vr_url = {picture: '', url: ''}
								}
								if (!item.video_url) {
									item.video_url = {picture: '', url: []}
								} else {
									item.video_url.url = item.video_url.url
										? [
												{
													url: item.video_url.url,
													status: 'done',
													uid: '0',
													name: item.video_url.url.split('.com/')[1]
												}
										  ]
										: []
								}
							})
						this.roomList = list
						if (list.length > 0) this.$nextTick(() => this.initRoomSort())
					}
					this.$store.dispatch('loading/endContentLoading')
				})
				.catch(() => this.$store.dispatch('loading/endContentLoading'))
		},
		/**
		 * 分类排序
		 * oldIndex:谁
		 * newIndex:移动到哪儿
		 */
		initCateSortable() {
			const table = document.querySelector('#table tbody')
			// eslint-disable-next-line no-unused-vars
			const sortableObj = new Sortable(table, {
				handle: '.ant-table-row',
				animation: 150,
				sort: true,
				onEnd: ({newIndex, oldIndex}) => {
					if (newIndex === oldIndex) return
					const currRow = this.categoryList.splice(oldIndex, 1)[0]
					this.categoryList.splice(newIndex, 0, currRow)
					this.sortCategory()
				}
			})
		},
		/**
		 * 餐位排序
		 * oldIndex:谁
		 * newIndex:移动到哪儿
		 */
		initRoomSort() {
			const list = document.querySelector('#room-list')
			// eslint-disable-next-line no-unused-vars
			const sortableObj = new Sortable(list, {
				handle: '.room-move-item',
				animation: 150,
				sort: true,
				onEnd: ({newIndex, oldIndex}) => {
					if (newIndex === oldIndex) return
					const currRow = this.roomList.splice(oldIndex, 1)[0]
					this.roomList.splice(newIndex, 0, currRow)
					this.sortRoom()
				}
			})
		},
		sortCategory() {
			sortRoomCategory({
				category: this.categoryList.map((item, index) => ({
					id: item.id,
					sort: index
				}))
			}).then(res => {})
		},
		sortRoom() {
			sortRoom({
				room: this.roomList.map((item, index) => ({
					id: item.id,
					sort: index
				}))
			}).then(res => {
				if (res.code === 0) {
					// 更新排序后包间的sort字段
					this.roomList.forEach((item, index) => {
						item.sort = index
					})
				}
			})
		},
		validatorType() {
			this.$refs.roomForm.clearValidate()
		},
		/**
		 * @description: 选中设施/取选设施
		 * @return {*}
		 */
		onSelectFacility(target) {
			// 选中
			if (!this.roomTypeFacilitiesValue.includes(target)) {
				this.roomTypeFacilitiesValue.push(target)
			} else {
				// 取消选中
				let tmpRoomTypeFacilitiesValue = this.roomTypeFacilitiesValue.filter(item => item !== target)

				this.roomTypeFacilitiesValue = _.cloneDeep(tmpRoomTypeFacilitiesValue)
			}
		},
		/**
		 * @description: 选中餐位设施/取选设施
		 * @return {*}
		 */
		onSelectRoomFacility(target) {
			// 选中
			if (!this.roomFacilitiesValue.includes(target)) {
				this.roomFacilitiesValue.push(target)
			} else {
				// 取消选中
				let tmpRoomFacilitiesValue = this.roomFacilitiesValue.filter(item => item !== target)

				this.roomFacilitiesValue = _.cloneDeep(tmpRoomFacilitiesValue)
			}
		},
		/**
		 * @description: 选中餐位可选菜单值/取选餐位可选菜单值
		 * @return {*}
		 */
		onSelectMenuCategory(target) {
			// 选中
			if (!this.menuCategoryValue.includes(target)) {
				this.menuCategoryValue.push(target)
			} else {
				// 取消选中
				let tmpMenuCategoryValue = this.menuCategoryValue.filter(item => item !== target)

				this.menuCategoryValue = _.cloneDeep(tmpMenuCategoryValue)
			}
		},
		/**
		 * @description: 订座时间单选
		 * @return {*}
		 */
		onChangeDingTimes(type, value) {
			if (type === 1) {
				this.roomTypeBeforeDay = value
				this.roomTypeDisabledHour = !value
			} else {
				this.roomTypeBeforeDay = !value
				this.roomTypeDisabledHour = value
			}
		},
		/**
		 * @description: 订座对外展示开关
		 * @return {*}
		 */
		onChangeSwitch() {
			this.roomTypeForm.ding_show = this.roomTypeForm.ding_show === 1 ? 0 : 1
		}
	},
	watch: {
		selectedRow: {
			handler() {
				this.getRoomList()
			}
		}
	},
	computed: {
		// 用于显示的值
		displayEarlyDay: {
			get() {
				// 如果 roomTypeBeforeDay 为 false，则返回空字符串，防止显示原值
				return this.roomTypeBeforeDay ? this.roomTypeForm.ding_times.early_day : ''
			},
			set(value) {
				// 更新绑定值
				this.roomTypeForm.ding_times.early_day = value
			}
		},
		displayDisabledHour: {
			get() {
				// 如果 roomTypeBeforeDay 为 false，则返回空字符串，防止显示原值
				return this.roomTypeDisabledHour ? this.roomTypeForm.ding_times.near_hour : ''
			},
			set(value) {
				// 更新绑定值
				this.roomTypeForm.ding_times.near_hour = value
			}
		}
	}
}
</script>

<style scoped>
.cate-empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ccenter;
	height: calc(100vh - 150px);
}

.cate-empty-img {
	width: 432px;
	height: 250px;
	margin-bottom: 7px;
}

.cate-list {
	background-color: white;
	margin-right: 6px;

	min-width: 208px;
	height: 100%;
	min-height: calc(100vh - 150px);
	border-radius: 4px;
	padding: 25px 22px;
}

.room-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.room-setting {
	display: flex;
	min-height: calc(100vh - 150px);
	background-color: #f0f2f5;
}

.room-list {
	flex: 1;
	height: 100%;
	background-color: white;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 25px 22px;
}

::v-deep .ant-table-row {
	cursor: pointer;
}

::v-deep .selected-row {
	background-color: #fff4f0;
}

.room {
	width: 100%;
	height: 174px;
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #e8e8e8;
}

.room:hover {
	box-shadow: 0 2px 8px 1px #f2f2f2;
}

.room-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 132px;
	border-bottom: 1px solid #e8e8e8;
	box-sizing: border-box;
	padding: 12px;
}

.room-footer {
	width: 100%;
	background-color: white;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	height: 40px;
	background: #f9f9f9;
	border-radius: 0 0 2px 2px;
}

.room-number {
	font-size: 16px;
	color: #333333;
	text-align: center;
	line-height: 24px;
}

.room-name {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 6px;
}

.subscribe-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100% - 20px);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	padding: 20px;
}

.ant-modal-body {
	padding: 24px 24px 0;
}

.facility-list {
	flex-wrap: wrap;
}

.facility-list .facility-item {
	padding: 0 10px;
	margin: 4px 8px 0 0;
	height: 32px;
	line-height: 32px;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	box-shadow: 0 2px 6px 0 #0000000d;
	font-size: 14px;
	color: #000;
}

.facility-list .facility-item:hover {
	background: #fff4f3;
	border: 1px solid #ffe6e3;
	cursor: pointer;
}

.facility-list .facility-item_checked {
	background: #fff4f3;
	border: 1px solid #ffe6e3;
}

.facility-list .facility-item_hidden:hover {
	background: #efefef;
	border: 1px solid #efefef;
	cursor: pointer;
}

.facility-list .facility-item_hidden_checked {
	background: #efefef;
	border: 1px solid #efefef;
}

.staple-checkbox ::v-deep .ant-checkbox {
	margin: 0 8px 8px 0 !important;
}

.staple-date {
	padding: 4px 0 20px;
}

.staple-number {
	padding-bottom: 0;
}

.staple-date .justify-center:last-child {
	margin-left: 40px;
}

.staple-date p {
	flex-shrink: 0;
	height: 32rpx;
	line-height: 32px;
}

.staple-date .left {
	margin-right: 8px;
}

.staple-date .right {
	margin-left: 8px;
}

.room-type-cover {
	width: 40px;
	height: 40px;
	vertical-align: middle;
}
</style>
