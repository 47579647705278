/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-11-05 18:48:29
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2025-01-03 18:36:44
 * @Description: 
 */
import request from '@/utils/request'

// 菜单分类设置相关
export const category = {
  list: () => request.post('/work/shop_menus/menu_category/getList'),
  save: params => request.post('/work/shop_menus/menu_category/saveCategory', params),
  delete: params => request.post('/work/shop_menus/menu_category/deleteCategory', params),
  orderCategory: params => request.post('/work/shop_menus/menu_category/orderCategory', params)
}

// 菜单菜品设置相关
export const menu = {
  list: params => request.post('/work/shop_menus/menu/getList', params),
  save: params => request.post('/work/shop_menus/menu/saveMenu', params),
  delete: params => request.post('/work/shop_menus/menu/deleteMenu', params),
  deleteSpecs: params => request.post('/work/shop_menus/menu/deleteSpec', params),
  orderMenu: params => request.post('/work/shop_menus/menu/orderMenu', params),
}



// 规格设置相关
export const config = {
  list: () => request.post('/work/shop_menus/spec/getList'),
  save: params => request.post('/work/shop_menus/spec/saveSpec', params),
  delete: params => request.post('/work/shop_menus/spec/deleteSpec', params),
  saveCategory: params => request.post('/work/shop_menus/spec_category/saveCategory', params),
  deleteCategory: params => request.post('/work/shop_menus/spec_category/deleteCategory', params)
}