<template>
  <div style="display: flex">
    <a-upload
      :disabled="disabled"
      :class="{ uploadNoList: !showUploadList }"
      list-type="picture-card"
      :showUploadList="showUploadList"
      :beforeUpload="beforeUpload"
      :customRequest="customRequest"
      :file-list="fileList"
      @preview="handlePreview"
      :remove="remove"
      :multiple="$attrs.multiple">
      <!-- 拖拽，无动画 -->
      <div
        v-if="!showUploadList"
        class="showUpLoadList"
        id="imgTable"
        ref="imgTable">
        <!-- 拖拽图片, 修改文件顺序 -->
        <div class="itemBox imgItem" v-for="item in fileList" :key="item.url" @click.stop>
          <img :src="item.url" />
          <div class="operate_box">
            <a-icon @click.stop="handlePreview(item)" type="eye"></a-icon>
            <a-icon @click.stop="remove(item)" type="delete"></a-icon>
          </div>
        </div>

        <div
          class="itemBox uploadBtn"
          v-if="fileList.length < max && modelType === 'Arr'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ title }}</div>
        </div>
        <div
          class="itemBox uploadBtn"
          v-else-if="modelType === 'Str' && fileList.length === 0">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ title }}</div>
        </div>
      </div>

      <!-- 不拖拽，有动画 -->
      <div
        v-if="fileList.length < max && modelType === 'Arr' && showUploadList">
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">{{ title }}</div>
      </div>
      <div
        v-else-if="
          modelType === 'Str' && fileList.length === 0 && showUploadList
        ">
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">{{ title }}</div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false">
      <img alt="example" style="width: 100%; margin-top: 20px;" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { ossconfig } from '@/api'
import request from '@/utils/request'
import _ from 'lodash'
import Sortable from 'sortablejs'

export default {
  name: 'imgUploader',
  props: {
    value: {
      required: true,
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | String,
    },
    /* value 的类型 必须是 Str 或者 Arr 其中的一个 */
    modelType: {
      default: 'Str',
      type: String,
      validator: (value) => ['Arr', 'Str'].indexOf(value) !== -1,
    },
    title: {
      default: '上传照片',
      type: String,
    },
    max: {
      default: 1,
      type: Number,
    },
    showUploadList: { 
      type: Boolean, 
      default: true 
    },
    disabled:{
      type: Boolean, 
      default: false 
    }
  },
  data() {
    return {
      fileList: [],
      loading: false,
      previewVisible: false,
      previewImage: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.reLoad()
      },
    },
  },
  mounted() {
    !this.showUploadList && this.createSortable()
  },
  methods: {
    reLoad() {
      this.$nextTick(() => {
        if (this.value) {
          if (this.modelType === 'Arr') {
            this.fileList = this.value.map((item, index) => ({
              url: item,
              status: 'done',
              uid: index,
              name: item,
            }))
            !this.showUploadList && this.createSortable()
          } else if (this.modelType === 'Str') {
            this.fileList = [
              { url: this.value, status: 'done', uid: '0', name: this.value },
            ]
          }
        } else {
          this.fileList = []
        }
      })
    },
    async handlePreview(file) {
      this.previewImage = file.url
      this.previewVisible = true
    },
    remove(file) {
      if(this.disabled) return
      const fileList = _.cloneDeep(this.fileList)
      const index = fileList.indexOf(
        fileList.find((item) => item.uid === file.uid)
      )
      fileList.splice(index, 1)
      this.fileList = fileList.map((item, index) => {
        item.uid = index
        return item
      })
      this.changeModel(this.fileList)
      this.$emit('remove')
    },
    changeModel(e) {
      if (this.modelType === 'Arr') {
        this.$emit(
          'input',
          e.map((item) => item.url)
        )
      } else if (this.modelType === 'Str') {
        this.$emit('input', e[0]?.url || '')
      }
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
          file.type === 'image/jpeg' || file.type === 'image/png'
        if (isJpgOrPng) resolve()
        else {
          this.$message.warning('请上传jpg/png格式的图片！')
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
      })
    },
    async customRequest(file) {
      this.loading = true
      const {
        data: { url: ossUrl,cdn_url, multipart_params: multipartParams },
      } = await ossconfig()
      const formData = new FormData()
      const name = this.random_string(15) + this.formatFileName(file.file.name)

      Object.keys(multipartParams).forEach((index) => {
        if (index === 'key') {
          multipartParams.key += name
        }
        formData.append(index, multipartParams[index])
      })
      formData.append('file', file.file)
      formData.append('name', name)
      await request({ url: ossUrl, method: 'post', data: formData }).then(
        (res) => {
          this.fileList.push({
            name: res.data.filename,
            url: cdn_url + res.data.filename,
            status: 'done',
            uid: this.fileList.length + 1,
          })
          this.changeModel(this.fileList)
          this.$emit('success')
        }
      )
      this.loading = false
    },
    random_string(e) {
      e = e || 32
      const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const a = t.length
      let n = ''
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },
    formatFileName(name) {
      const index = name.lastIndexOf('.')
      if (index !== -1) {
        return name.substring(index)
      } else {
        return ''
      }
    },
    // 创建拖拽对象
    createSortable() {
      this.$nextTick(() => {
        const imgTable = document.getElementById('imgTable')
        let sortableObj = null
        if (imgTable) {
          // eslint-disable-next-line no-unused-vars
          sortableObj = new Sortable(imgTable, {
            animation: 150,
            handle: '.imgItem',
            draggable: '.imgItem',
            onEnd: ({ newIndex, oldIndex }) => {
              const fileList = JSON.parse(JSON.stringify(this.fileList))
              const changeItem = fileList[oldIndex]
              fileList.splice(oldIndex, 1)
              fileList.splice(newIndex, 0, changeItem)
              this.sortableObj = null
              this.changeModel(fileList)
            },
          })
        }
      })
    },

    // 穿透触发父组件的上传点击事件
    // deepClick(e) {
    //   this.$nextTick(() => {
    //     e.path[2].click()
    //   })
    // },
  },
}
</script>

<style scoped lang="less">
::v-deep .ant-upload-list-item,
::v-deep .ant-upload-list-picture-card-container,
::v-deep .ant-upload-select-picture-card {
  width: 141px;
  height: 141px;
}

.uploadNoList {
  ::v-deep {
    .ant-upload {
      width: 100%;
      border: none;
      overflow: hidden;
      padding: 0;
      background-color: #fff;
    }
  }
}

.showUpLoadList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  #imgTable {
    display: flex;
  }
  .itemBox {
    width: 141px;
    height: 141px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .operate_box {
      display: none;
      position: absolute;
      top: 8px;
      left: 8px;
      height: 123px;
      width: 123px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      align-items: center;
      justify-content: center;
      ::v-deep .anticon {
        margin: 0 4px;
      }
    }

    &:hover .operate_box {
      display: flex;
    }
  }
  .uploadBtn {
    width: 141px;
    height: 141px;
    padding: 8px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5;
    cursor: pointer;
    border: 1px dashed rgb(217, 217, 217);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    .ant-upload-text {
      margin-top: 8px;
    }
  }
}
</style>
